import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, TextareaAutosize } from '@mui/material';
import { doc, getDoc, updateDoc, deleteDoc, collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { styled } from '@mui/material/styles';

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1.5, 1),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[700]}`,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: 'none',
    },
  }));

const EditSong = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [song, setSong] = useState(null);
  const [songContent, setSongContent] = useState({});
  const [songDetails, setSongDetails] = useState({});

  useEffect(() => {
    const loadSongData = async () => {
      try {
        // Carregar dados principais da canção
        const songDocRef = doc(firestore, `songs/${id}`);
        const songDocSnap = await getDoc(songDocRef);

        if (songDocSnap.exists()) {
          setSong(songDocSnap.data());
        }

        // Carregar dados da subcoleção songs_content
        const songContentCollectionRef = collection(firestore, `songs/${id}/songs_content`);
        const songContentDocsSnap = await getDocs(songContentCollectionRef);
        if (!songContentDocsSnap.empty) {
          const contentData = songContentDocsSnap.docs[0].data();
          contentData.chords = contentData.chords.replace(/\\n/g, '\n'); // Substituir \n por quebras de linha reais
          setSongContent(contentData);
        }

        // Carregar dados da subcoleção songs_details
        const songDetailsCollectionRef = collection(firestore, `songs/${id}/songs_details`);
        const songDetailsDocsSnap = await getDocs(songDetailsCollectionRef);
        if (!songDetailsDocsSnap.empty) {
          setSongDetails(songDetailsDocsSnap.docs[0].data());
        }
      } catch (error) {
        console.error('Erro ao carregar os dados da canção:', error);
      }
    };
    loadSongData();
  }, [id]);

  const handleSave = async () => {
    try {
      // Atualizar dados principais da canção
      const songDocRef = doc(firestore, `songs/${id}`);
      await updateDoc(songDocRef, {
        name: song.name,
        artist_name: song.artist_name,
        genre: song.genre,
      });

      // Atualizar dados da subcoleção songs_content
      const songContentCollectionRef = collection(firestore, `songs/${id}/songs_content`);
      const songContentDocsSnap = await getDocs(songContentCollectionRef);
      if (!songContentDocsSnap.empty) {
        const songContentDocRef = songContentDocsSnap.docs[0].ref;
        await updateDoc(songContentDocRef, {
          chords: songContent.chords.replace(/\n/g, '\\n'), // Substituir quebras de linha reais por \n
          tone: songContent.tone,
          capo: songContent.capo || '',
        });
      }

      // Atualizar dados da subcoleção songs_details
      const songDetailsCollectionRef = collection(firestore, `songs/${id}/songs_details`);
      const songDetailsDocsSnap = await getDocs(songDetailsCollectionRef);
      if (!songDetailsDocsSnap.empty) {
        const songDetailsDocRef = songDetailsDocsSnap.docs[0].ref;
        await updateDoc(songDetailsDocRef, {
          composer: songDetails.composer,
          producer: songDetails.producer,
          other: songDetails.other,
          release: songDetails.release,
        });
      }

      alert('Canção atualizada com sucesso!');
      navigate(`/song/${id}`);
    } catch (error) {
      console.error('Erro ao salvar os dados da canção:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(firestore, `songs/${id}`));
      alert('Canção excluída com sucesso!');
      navigate('/');
    } catch (error) {
      console.error('Erro ao excluir a canção:', error);
    }
  };

  const handleChange = (field, value, section) => {
    if (section === 'song') {
      setSong((prev) => ({ ...prev, [field]: value }));
    } else if (section === 'content') {
      setSongContent((prev) => ({ ...prev, [field]: value }));
    } else if (section === 'details') {
      setSongDetails((prev) => ({ ...prev, [field]: value }));
    }
  };

  if (!song) {
    return <Typography>Carregando...</Typography>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Editar Canção
      </Typography>

      <TextField
        placeholder="Nome da Canção"
        value={song.name}
        onChange={(e) => handleChange('name', e.target.value, 'song')}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        placeholder="Nome do Artista"
        value={song.artist_name}
        onChange={(e) => handleChange('artist_name', e.target.value, 'song')}
        fullWidth
        disabled
        sx={{ marginBottom: 2 }}
      />
      <TextField
        placeholder="Gênero"
        value={song.genre}
        onChange={(e) => handleChange('genre', e.target.value, 'song')}
        fullWidth
        sx={{ marginBottom: 2 }}
      />

      <StyledTextarea
        id="short-bio"
        aria-label="Biografia Curta"
        minRows={5}
        maxRows={10}
        placeholder="Cifra da música"
        value={songContent.chords}
        onChange={(e) => handleChange('chords', e.target.value, 'content')}
      />
      <TextField
        placeholder="Tom"
        value={songContent.tone}
        onChange={(e) => handleChange('tone', e.target.value, 'content')}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        placeholder="Capotraste"
        value={songContent.capo}
        onChange={(e) => handleChange('capo', e.target.value, 'content')}
        fullWidth
        sx={{ marginBottom: 2 }}
      />

      <TextField
        placeholder="Compositor"
        value={songDetails.composer}
        onChange={(e) => handleChange('composer', e.target.value, 'details')}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        placeholder="Produtor"
        value={songDetails.producer}
        onChange={(e) => handleChange('producer', e.target.value, 'details')}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        placeholder="Outros"
        value={songDetails.other}
        onChange={(e) => handleChange('other', e.target.value, 'details')}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TextField
        placeholder="Lançamento"
        value={songDetails.release}
        onChange={(e) => handleChange('release', e.target.value, 'details')}
        fullWidth
        sx={{ marginBottom: 2 }}
      />

      <Box sx={{ marginTop: 4 }}>
        <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginRight: 2 }}>
          Salvar
        </Button>
        <Button variant="outlined" color="secondary" onClick={() => navigate(`/song/${id}`)}>
          Voltar
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
          sx={{ marginLeft: 2 }}
        >
          Excluir Cifra
        </Button>
      </Box>
    </Box>
  );
};

export default EditSong;
