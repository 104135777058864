// SongActions.js
import React, { useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ToneSelector from './ToneSelector';

const SongActions = ({
  selectedTab,
  tone,
  capo,
  setCapo,
  handleToneChange,
  handleOpenReportDialog,
  songName,
  artistName,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  
  if (selectedTab !== 'Cifra') {
    return null;
  }

  

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: songName,
          text: `Hey, olha só esta cifra que encontrei: ${songName} - ${artistName}`,
          url: window.location.href,
        })
        .then(() => {
          console.log('Compartilhamento bem-sucedido');
        })
        .catch((error) => {
          console.error('Erro ao compartilhar:', error);
        });
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      // Fallback para navegadores que suportam a Clipboard API
      navigator.clipboard.writeText(window.location.href)
        .then(() => {
          setOpenSnackbar(true);
          console.log('Link copiado para a área de transferência');
        })
        .catch((error) => {
          console.error('Erro ao copiar o link:', error);
        });
    } else {
      // Fallback final se a Clipboard API também não estiver disponível
      alert('Não foi possível compartilhar o link.');
    }
  };

  return (
    <>
      <ToneSelector 
        tone={tone} 
        setTone={handleToneChange} 
        capo={capo}
        setCapo={setCapo}
      />
      
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenReportDialog}
        sx={{ marginBottom: 2, marginRight: 2 }}
      >
        Reportar erros
      </Button>

      <Button
        variant="contained"
        color="primary"
        startIcon={<ShareIcon />}
        onClick={handleShare}
        sx={{ marginBottom: 2 }} 
      >
        Compartilhar
      </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message="O link da música foi copiado"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};

export default SongActions;