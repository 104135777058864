import React, { useEffect, useState } from 'react';
import { Box, Typography, Select, MenuItem } from '@mui/material';

const majorTones = [
  'C', 'Db', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'
];

const minorTones = [
  'Cm', 'C#m', 'Dm', 'Ebm', 'Em', 'Fm', 'F#m', 'Gm', 'G#m', 'Am', 'Bbm', 'Bm'
];

const capoOptions = Array.from({ length: 8 }, (_, i) => `${i + 1}º casa`);

const ToneSelector = ({ tone, setTone, capo, setCapo }) => {
  const [isMinor, setIsMinor] = useState(false); // Estado para verificar se o tom é menor

  // Função para verificar se o tom é menor ou maior
  useEffect(() => {
    if (tone && tone.includes('m')) {
      setIsMinor(true);
    } else {
      setIsMinor(false);
    }
  }, [tone]);

  const tonesToDisplay = isMinor ? minorTones : majorTones; // Define se vai exibir tons menores ou maiores

  return (
    <Box sx={{ marginBottom: 3, display: 'flex', alignItems: 'center', gap: 3 }}>
      <Box>
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          Alterar o tom
        </Typography>
        <Select
          value={tone}
          onChange={(e) => setTone(e.target.value)}
          displayEmpty
          sx={{
            minWidth: 150,
            border: 'none', // Remove a borda azul
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Remove a borda padrão do campo select
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Remove a borda quando o usuário passa o mouse
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Remove a borda quando o campo está em foco
            },
          }}
        >
          <MenuItem value={tone}>{tone}</MenuItem> {/* Valor original */}
          {tonesToDisplay.map((t) => (
            <MenuItem key={t} value={t}>{t}</MenuItem> // Exibe os tons com base se é maior ou menor
          ))}
        </Select>
      </Box>

      <Box>
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          Capotraste
        </Typography>
        <Select
          value={capo || "Nenhum"}
          onChange={(e) => setCapo(e.target.value)}
          displayEmpty
          sx={{
            minWidth: 150,
            border: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        >
          <MenuItem value="Nenhum">Nenhum</MenuItem>
          {capoOptions.map((option, index) => (
            <MenuItem key={index} value={option}>{option}</MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default ToneSelector;
