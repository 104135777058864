// TermsConditions.js
import React from 'react';
import { Box, Typography, Container, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../common/NavigationBar';

const TermsConditions = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#1e1e1e',
        color: '#fff',
        minHeight: '100vh',
        padding: 2,
      }}
    >
      <NavigationBar />
      
      <Container maxWidth="sm" sx={{ marginBottom: '5rem' }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            marginBottom: 3,
            textAlign: 'center',
          }}
        >
          Termos e Condições de Uso
        </Typography>

        <Typography
          variant="body2"
          gutterBottom
          sx={{
            marginBottom: 3,
            fontStyle: 'italic',
            textAlign: 'center',
          }}
        >
          Última atualização: 16/10/2024
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 1 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          1. Aceitação dos Termos
        </Typography>
        <Typography variant="body2" gutterBottom>
          Ao baixar, acessar ou utilizar o aplicativo Worship Cifras
          ("Aplicativo"), você concorda em cumprir e estar vinculado a estes
          Termos e Condições de Uso ("Termos"). Se você não concorda com estes
          Termos, não utilize o Aplicativo.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 2 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          2. Uso do Aplicativo
        </Typography>
        <Typography variant="body2" gutterBottom>
          O Worship Cifras é destinado ao consumo pessoal e não comercial de
          cifras musicais gospel. Você concorda em utilizar o Aplicativo somente
          para fins legais e de acordo com estes Termos e todas as leis, regras
          e regulamentos aplicáveis.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 3 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          3. Propriedade Intelectual
        </Typography>
        <Typography variant="body2" gutterBottom>
          Todos os conteúdos presentes no Aplicativo, incluindo, mas não se
          limitando a, textos, gráficos, logos, ícones, imagens, clipes de
          áudio, downloads digitais e compilações de dados, são propriedade
          exclusiva do Worship Cifras ou de seus licenciantes e estão protegidos
          pelas leis de direitos autorais e outras leis de propriedade
          intelectual.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 4 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          4. Conduta do Usuário
        </Typography>
        <Typography variant="body2" gutterBottom>
          4.1 Você concorda em não utilizar o Aplicativo para qualquer finalidade
          ilegal ou não autorizada.
          <br />
          4.2 É proibido transmitir qualquer vírus ou código de natureza
          destrutiva.
          <br />
          4.3 Você não deve tentar obter acesso não autorizado a qualquer parte
          do Aplicativo ou de seus sistemas relacionados.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 5 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          5. Limitação de Responsabilidade
        </Typography>
        <Typography variant="body2" gutterBottom>
          5.1 O Worship Cifras não garante que o Aplicativo estará disponível de
          forma ininterrupta ou livre de erros.
          <br />
          5.2 Em nenhuma circunstância o Worship Cifras será responsável por
          quaisquer danos diretos, indiretos, incidentais, especiais ou
          consequenciais resultantes do uso ou da incapacidade de usar o
          Aplicativo.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 6 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          6. Modificações dos Termos
        </Typography>
        <Typography variant="body2" gutterBottom>
          Reservamo-nos o direito de modificar ou atualizar estes Termos a
          qualquer momento, sem aviso prévio. As alterações entrarão em vigor
          imediatamente após a publicação no Aplicativo. É sua responsabilidade
          revisar periodicamente estes Termos.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 7 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          7. Rescisão
        </Typography>
        <Typography variant="body2" gutterBottom>
          Podemos suspender ou encerrar seu acesso ao Aplicativo a qualquer
          momento, sem aviso prévio ou responsabilidade, por qualquer motivo,
          incluindo, mas não se limitando, à violação destes Termos.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 8 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          8. Privacidade
        </Typography>
        <Typography variant="body2" gutterBottom>
          O uso do Aplicativo também é regido por nossa Política de Privacidade,
          que está incorporada a estes Termos por esta referência.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 9 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          9. Lei Aplicável
        </Typography>
        <Typography variant="body2" gutterBottom>
          Estes Termos serão regidos e interpretados de acordo com as leis do
          Brasil, sem considerar seus conflitos de provisões legais.
        </Typography>

        <Divider sx={{ backgroundColor: '#555', marginBottom: 3, marginTop: 3 }} />

        {/* Seção 10 */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          10. Contato
        </Typography>
        <Typography variant="body2" gutterBottom>
          Se você tiver quaisquer dúvidas ou preocupações sobre estes Termos,
          entre em contato conosco através do e-mail:{' '}
          <a href="mailto:contato@worshipcifras.com.br" style={{ color: '#A52422' }}>
            contato@worshipcifras.com.br
          </a>
          .
        </Typography>
      </Container>
    </Box>
  );
};

export default TermsConditions;
