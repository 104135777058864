import { getFirestore, doc, getDoc, collection, getDocs } from 'firebase/firestore';

export const fetchSongData = async (id) => {
  const db = getFirestore();
  const songRef = doc(db, 'songs', id);
  const docSnap = await getDoc(songRef);

  if (docSnap.exists()) {
    const songData = docSnap.data();

    // Busca o primeiro documento na subcoleção 'songs_content'
    const songContentRef = collection(db, `songs/${id}/songs_content`);
    const contentSnap = await getDocs(songContentRef);
    const songContentData = !contentSnap.empty ? contentSnap.docs[0].data() : null;
    
    const songContentDataId = contentSnap.docs[0].id;

    // Busca os detalhes da música na subcoleção 'songs_details'
    const songDetailsRef = collection(db, `songs/${id}/songs_details`);
    const detailsSnap = await getDocs(songDetailsRef);
    const songDetailsData = !detailsSnap.empty ? detailsSnap.docs[0].data() : null;

    return { songData, songContentData, songDetailsData, songContentDataId };
  }

  throw new Error('No such document!');
};
