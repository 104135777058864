// formatSong.js
import React from 'react';

// Função para verificar se uma palavra é um acorde válido
const isValidChord = (word) => {
  const regex = new RegExp(
    '^' +
    '[A-G]' +                // Nota raiz
    '([#b])?' +              // Opcional sustenido ou bemol
    '(maj|min|dim|aug|sus[2|4]?|add[0-9]+|m|M|7M|[0-9]+)?' + // Qualificador de acorde
    '([0-9]+)?' +            // Número opcional
    '(\\/[A-G]([#b])?)?' +   // Inversão de baixo opcional
    '$',
    'i' // Flag para case-insensitive
  );
  return regex.test(word);
};

// Função para analisar o texto e extrair tokens com base nos escapes
function parseText(text) {
  const tokens = [];
  const regex = /(\[.*?\])|(".*?")|([^\["\]]+)/gs;
  const matches = text.matchAll(regex);
  for (const match of matches) {
    if (match[1]) {
      // É uma parte da música entre []
      const content = match[1]; // Mantém os []
      tokens.push({ type: 'part', content });
    } else if (match[2]) {
      // É uma letra entre ""
      const content = match[2].slice(1, -1); // Remove as ""
      tokens.push({ type: 'lyrics', content });
    } else if (match[3]) {
      // Outro texto (possivelmente acordes)
      const content = match[3];
      tokens.push({ type: 'chord', content });
    }
  }
  return tokens;
}

// Função para formatar a música e retornar componentes de acordes, letras e lista de acordes
export const formatSong = (text, onChordClick = () => {}) => {
  
  const tokens = parseText(text.replace(/\\n/g, '\n'));
  const chordsComponent = [];
  const lyricsComponent = [];
  const chordsSet = new Set();

  tokens.forEach((token, index) => {
    const lines = token.content.split('\n');

    lines.forEach((line, lineIndex) => {
      const trimmedLine = line.trim();
      if (token.type === 'part') {
        // Parte da música, estilizada em cinza, mantendo os []
        const partComponent = (
          <p key={`${index}-${lineIndex}`} style={{ color: 'gray', margin: '5px 0' }}>
            {trimmedLine}
          </p>
        );
        chordsComponent.push(partComponent);
        lyricsComponent.push(partComponent);
      } else if (token.type === 'lyrics') {
        // Letra da música, estilizada em branco
        const lyricsLineComponent = (
          <p key={`${index}-${lineIndex}`} style={{ margin: '5px 0' }}>
            <span style={{ color: 'white' }}>{trimmedLine}</span>
          </p>
        );
        chordsComponent.push(lyricsLineComponent);
        lyricsComponent.push(lyricsLineComponent);
      } else if (token.type === 'chord') {
        // Processar linha que pode conter acordes ou letras
        const words = trimmedLine.split(' ').filter(word => word.trim().length > 0);
        const isChordOnlyLine = words.every(word => isValidChord(word));

        if (isChordOnlyLine) {
          // Linha de acordes
          words.forEach((chord) => {
            chordsSet.add(chord);
          });
          const chordsLineComponent = (
            <p key={`${index}-${lineIndex}`} style={{ margin: '5px 0' }}>
              {words.map((chord, idx) => (
                <span
                  key={idx}
                  style={{ color: '#A52422', cursor: 'pointer' }}
                  onClick={() => onChordClick(chord)}
                >
                  {chord}{' '}
                </span>
              ))}
            </p>
          );
          chordsComponent.push(chordsLineComponent);
          // Não adicionamos nada ao lyricsComponent para linhas de acordes
        } else {
          // Linha que pode conter letras e acordes misturados
          const mixedLineComponent = (
            <p key={`${index}-${lineIndex}`} style={{ margin: '5px 0' }}>
              {words.map((word, idx) => {
                const wordLower = word.toLowerCase();

                // Regra específica para "Em", "A", "E" quando em linhas de letras
                if ((wordLower === 'em' || wordLower === 'a' || wordLower === 'e') && trimmedLine.length > 8) {
                  return <span key={idx} style={{ color: 'white' }}>{word} </span>;
                }

                if (isValidChord(word)) {
                  // Palavra é um acorde
                  chordsSet.add(word);
                  return (
                    <span
                      key={idx}
                      style={{ color: '#A52422', cursor: 'pointer' }}
                      onClick={() => onChordClick(word)}
                    >
                      {word}{' '}
                    </span>
                  );
                } else {
                  // Palavra é parte da letra
                  return <span key={idx} style={{ color: 'white' }}>{word} </span>;
                }
              })}
            </p>
          );
          chordsComponent.push(mixedLineComponent);
          lyricsComponent.push(
            <p key={`${index}-${lineIndex}`} style={{ margin: '5px 0' }}>
              {words.map((word, idx) => {
                const wordLower = word.toLowerCase();

                // Regra específica para "Em", "A", "E" quando em linhas de letras
                if ((wordLower === 'em' || wordLower === 'a' || wordLower === 'e') && trimmedLine.length > 8) {
                  return <span key={idx} style={{ color: 'white' }}>{word} </span>;
                }

                if (!isValidChord(word)) {
                  // Palavra é parte da letra
                  return <span key={idx} style={{ color: 'white' }}>{word} </span>;
                } else {
                  // Palavra é um acorde, não adicionamos ao lyricsComponent
                  return null;
                }
              })}
            </p>
          );
        }
      }
    });
  });

  const chordsList = Array.from(chordsSet);

  return { chordsComponent, lyricsComponent, chordsList };
};
