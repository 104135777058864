import React, { useState } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar, Alert } from '@mui/material';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, Configure } from 'react-instantsearch-dom';
import TopBar from '../common/TopBar';
import FooterNavbar from '../common/FooterNavbar';
import SearchField from './SearchField';
import SectionCards from '../common/SectionCards';
import Hit from './Hit';
import { firestore, auth } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const searchClient = algoliasearch('6QOJL3SBOW', 'f2448d5d2027753a6d0842aac18426ea');

const categories = [
  { title: 'Worships em alta', tag: 'worship' },
  { title: 'Santa Ceia', tag: 'santaceia' },
  { title: 'Animadas', tag: 'animadas' },
  { title: 'Trend Songs', tag: 'trend' },
  { title: 'As mais clássicas', tag: 'classicas' },
  { title: 'Acústicas', tag: 'acusticas' },
];

const Search = () => {
  const [query, setQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [artist, setArtist] = useState('');
  const [songName, setSongName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleSendRequest = async () => {
    try {
      const user = auth.currentUser;
      const email = user ? user.email : userEmail || 'Anônimo';
      await addDoc(collection(firestore, 'songs_unregistered'), {
        artist,
        name: songName,
        user: email,
        status: 'new',
        created: serverTimestamp(),
        updated: serverTimestamp(),
      });
      setSnackbarMessage('Já recebemos o seu pedido, e a canção logo estará disponível em nossa plataforma. Obrigado por apoiar a comunidade!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Erro ao enviar pedido:', error);
      setSnackbarMessage('Ops, ocorreu um erro com o seu pedido, pode aguardar alguns instantes e tentar novamente? Se o erro persistir, não hesite em entrar em contato com a gente pelo Instagram ou Email.');
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
    handleCloseDialog();
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  return (
    <Box sx={{ pb: 7 }}>
      <TopBar />
      <InstantSearch
        searchClient={searchClient}
        indexName="songs"
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <Box sx={{ paddingX: 2, marginY: 2 }}>
          <SearchField />
        </Box>
        <Configure hitsPerPage={10} />
        {query.length > 0 && ( // Exibe resultados somente se o campo de busca não estiver vazio
          <Box 
            component="ul" 
            sx={{  
              padding: 0, 
              margin: 0 
            }}
          >
            <Hits hitComponent={Hit} />
          </Box>
        )}
      </InstantSearch>
      <SectionCards title="Que tal algumas sugestões" data={categories} />
      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpenDialog}
          sx={{ display: 'block', margin: '0 auto' }}
        >
          Não encontrei a música que buscava 😔
        </Button>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Qual é a música que você esta buscando?</DialogTitle>
        <DialogContent>          
          <TextField
            placeholder="Nome da Música"
            fullWidth
            value={songName}
            onChange={(e) => setSongName(e.target.value)}
            margin="dense"
          />
          <TextField
            placeholder="Nome do Artista"
            fullWidth
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
            margin="dense"
          />
          {!auth.currentUser && (
            <TextField
              placeholder="Seu Email (opcional)"
              fullWidth
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              margin="dense"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSendRequest} color="primary" variant="contained">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <FooterNavbar />
    </Box>
  );
};

export default Search;
